@import '@shared-styles/src/assets/scss/colors.scss';

.risk_wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;
  @media (max-width: 470px) {
    flex-direction: column-reverse;
    align-items: flex-end;

    .side_filter_container {
      height: max-content;
    }

    .risk_wrapper_container {
      width: 100% !important;
    }
  }
  .risk_wrapper_container {
    position: relative;
    height: 100%;
    width: calc(100% - 40px);
    padding-right: 5px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .risk-container {
      position: relative;
      padding-right: 5px;
      gap: 10px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      .dispute_container {
        width: 100%;
        gap: 15px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        .disputes {
          border-radius: 10px;
          background: $dark-bg-2;
          border: 1px solid rgba(163, 174, 208, 0.2);
          display: flex;
          padding: 20px;
          gap: 10px;
          .content {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .heading {
              white-space: nowrap;
              font-size: 12px;
              font-weight: 500;
              line-height: 14.08px;
              letter-spacing: -0.02em;
              text-align: left;
              color: var(--Sub-Text, #a3aed0);
            }
            .count {
              display: flex;
              gap: 5px;
              align-items: center;

              .total_count {
                color: var(--prim-ui, #f90182);
                font-size: 20px;
                font-weight: 700;
                line-height: 23.46px;
                letter-spacing: -0.02em;
              }
              .total_percent {
                color: var(--white, #ffffff);
                font-size: 14px;
                font-weight: 600;
                line-height: 16.42px;

                letter-spacing: -0.02em;
              }
            }
          }
          .graph {
            display: flex;
            gap: 2px;
            width: 100%;
            height: 45px;
            canvas {
              width: 100% !important;
            }
          }
        }
      }

      .risk_management {
        width: 100%;
        gap: 20px;
        display: grid;
        grid-template-rows: 1fr min-content;
        position: relative;
        .Common_Table {
          .MuiTable-root {
            min-height: 250px;
          }
        }
        .risk_management_table_container {
          border-radius: 10px;
          background: $dark-bg-2;
          border: 1px solid rgba(163, 174, 208, 0.2);
          .subscription_management_table {
            border-radius: 10px;

            .table_header {
              .MuiTableRow-head {
                .table_header_cell {
                  .MuiTableCell-stickyHeader {
                    background-color: rgba(163, 174, 208, 0.2) !important;
                  }
                }
                .MuiTableRow-root {
                  height: 48px;
                  padding: 0px 12px;
                  border-bottom: 1px solid rgba(163, 174, 208, 0.1);
                  background-color: $dark-bg-2 !important;

                  &.header_text {
                    color: $sub-text;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    white-space: nowrap;
                  }
                }
              }

              .flex-row {
                display: flex;
                align-items: center;
              }
            }

            .table_body {
              .table_row {
                .table_cell {
                  height: 64px;
                  padding: 0px 12px;
                  white-space: nowrap;
                  color: $white;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  border-bottom: 1px solid rgba(163, 174, 208, 0.1);
                }

                .link_btn {
                  cursor: pointer;
                  color: $sec-ui;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;

                  &:hover {
                    text-decoration-line: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
    .dashboard_grid {
      display: grid;
      grid-auto-flow: dense;
      gap: 10px;
      grid-template-columns: repeat(4, calc(25% - 8px));
      height: fit-content;
      padding-bottom: 20px;
      .row-full {
        grid-column: 1/-1;
      }

      .row-span-2 {
        grid-column: span 2;
      }

      .row-span-1 {
        grid-column: span 1;
      }
      @media screen and (max-width: 900px) {
        .row-span-2 {
          grid-column: 1/-1;
        }

        .row-span-1 {
          grid-column: span 2;
        }
      }
      .lg_card_container {
        border-radius: 20px;
        background: $dark-bg-2;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 38px;

          .header_text {
            color: $white;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
          }
        }

        .body {
          display: flex;

          .left_side_details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            .stats_details {
              display: flex;
              gap: 22px;
              flex-direction: column;

              .head_text {
                color: $helper-text;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                white-space: nowrap;
                display: flex;
                gap: 8px;
                white-space: nowrap;

                .indicator {
                  border-radius: 15px;
                  width: 15px;
                  height: 15px;
                }
              }

              .sub_text {
                color: $white;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                white-space: nowrap;
              }
            }
          }
        }

        .chart_wrapper {
          width: 100%;
          align-self: end;
          max-height: 250px;
          height: 100%;
          position: relative;
          .chart {
            min-height: 200px;
          }
        }

        .reason_code_wrapper {
          display: grid;
          grid-template-columns: 45% 1fr;
          gap: 10px;
          .status_container {
            flex-direction: column;
            max-height: 250px;
            overflow: auto;
            .status_wrapper {
              display: flex;
              gap: 5px;
              .status_color {
                width: 10px;
                height: 10px;
                margin-top: 2px;
                border-radius: 50px;
              }
            }
            .status_name {
              display: flex;
              flex-direction: column;
              width: 100%;
              color: var(--Sub-Text, #a3aed0);
              font-size: 12px;
              font-weight: 500;
              .card_name {
                line-height: 14.08px;
                letter-spacing: -0.02em;
              }
              .card_Data {
                font-size: 16px;
                font-weight: 600;
                color: $white;
                margin-top: 1px;
              }
            }
          }
          .reason_code_chart_wrapper {
            width: 80%;
            height: 250px;
            position: relative;
            margin: auto;
          }
        }
      }
      .values_detail {
        display: flex;
        gap: 3px;
        align-items: center;
        .value {
          font-size: 22px;
          font-weight: 700;
          line-height: 25.81px;
          letter-spacing: -0.02em;
          color: $white;
        }
        .percentage {
          font-size: 14px;
          font-weight: 700;
          line-height: 16.42px;
          letter-spacing: -0.02em;
          color: $sub-text;
        }
      }
      .status_container {
        display: flex;
        gap: 5px;
        .status {
          display: flex;
          gap: 5px;
          align-items: center;
          .status_color {
            width: 10px;
            height: 10px;
            border-radius: 50px;
          }
          .status_name {
            color: var(--Sub-Text, #a3aed0);
            font-size: 12px;
            font-weight: 500;
            line-height: 14.08px;
            letter-spacing: -0.02em;
            height: 14px;
          }
        }
      }
    }
  }
}
